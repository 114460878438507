'use client';

import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { ERROR_MESSAGES_CODE, ERROR_MESSAGES_MAP } from 'auth/types';
import { AlertCircleIcon, EyeIcon, EyeOffIcon } from 'lucide-react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  Alert,
  AlertDescription,
  AlertInfo,
  AlertTextContent,
} from '@axiom-ui/react/alert';
import { Button } from '@axiom-ui/react/button';
import { Card, CardContent, CardHeader } from '@axiom-ui/react/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@axiom-ui/react/form';
import { Input } from '@axiom-ui/react/input';
import { TextLink } from '@axiom-ui/react/text-link';
import { Body } from '@axiom-ui/react/typography';

import { useGetErrorMessageCallback } from '@/modules/auth/login/hooks/use-get-error-message-callback';
import { useLoginEmailPassword } from '@/modules/auth/login/hooks/use-login-email-password';
import PageFormSigninGoogleButton from '@/modules/auth/login/ui/page-form-signin-google-button';
import { useHydrationReady } from '@/modules/shared/hooks/use-hydration-ready';
import { Logo } from '@/modules/shared/ui/team-components/logo';

const GoogleSignInButtonClient = dynamic(
  () => import('@/modules/auth/login/ui/page-form-signin-google-button'),
  {
    loading: () => <PageFormSigninGoogleButton isLoading={true} />,
    ssr: false,
  },
);

const formSchema = z.object({
  email: z
    .string()
    .min(1, 'Campo obrigatório')
    .email('Verifique seu email de acesso'),
  password: z.string().min(1, 'Campo obrigatório'),
});

type FormSchemaType = z.infer<typeof formSchema>;

export function PageForm() {
  const [showPassword, setShowPassword] = useState(false);
  const searchParams = useSearchParams();
  const callbackErrorMessage = useGetErrorMessageCallback();
  const hydrationIsReady = useHydrationReady();

  const {
    mutate: mutateLoginEmailPassword,
    isLoading,
    error: emailPasswordError,
  } = useLoginEmailPassword();

  const error = emailPasswordError ?? callbackErrorMessage;

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  function onSubmit(data: FormSchemaType) {
    mutateLoginEmailPassword({
      email: data.email,
      password: data.password,
      nextUrl: searchParams.get('next') ?? '',
    });
  }

  return (
    <Card padding="lg" className="lg:border-1 border-0 shadow-none lg:shadow-5">
      <CardHeader className="relative flex flex-row items-center justify-center">
        <Logo />
      </CardHeader>

      <CardContent>
        <div className="grid gap-4">
          <Form {...form}>
            <form className="grid gap-4" onSubmit={form.handleSubmit(onSubmit)}>
              {error ? (
                <Alert
                  severity={
                    error ===
                    ERROR_MESSAGES_MAP.get(ERROR_MESSAGES_CODE.SessionExpired)
                      ? 'warning'
                      : 'danger'
                  }
                >
                  <AlertInfo>
                    <AlertCircleIcon />
                    <AlertTextContent>
                      <AlertDescription>{error}</AlertDescription>
                    </AlertTextContent>
                  </AlertInfo>
                </Alert>
              ) : null}
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>E-mail</FormLabel>
                    <FormControl>
                      <Input dimension="md">
                        <Input.Content>
                          <Input.Field
                            placeholder="Email de acesso"
                            autoCapitalize="none"
                            autoComplete="email"
                            autoCorrect="off"
                            id="email"
                            type="email"
                            disabled={!hydrationIsReady}
                            {...field}
                          />
                        </Input.Content>
                      </Input>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Senha</FormLabel>
                    <FormControl>
                      <Input dimension="md">
                        <Input.Content>
                          <Input.Field
                            placeholder="Digite aqui sua senha"
                            autoCapitalize="none"
                            autoComplete="password"
                            autoCorrect="off"
                            type={showPassword ? 'text' : 'password'}
                            disabled={!hydrationIsReady}
                            {...field}
                          />
                          <Input.TrailButton
                            aria-label={
                              showPassword ? 'Esconder senha' : 'Mostrar senha'
                            }
                            icon={showPassword ? EyeIcon : EyeOffIcon}
                            onClick={() => setShowPassword((show) => !show)}
                          />
                        </Input.Content>
                      </Input>
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <TextLink
                size="xs"
                href="/new/usuarios/recuperar-senha/"
                className="justify-self-end"
              >
                Esqueci minha senha
              </TextLink>
              <Button
                disabled={!hydrationIsReady}
                loading={isLoading || !hydrationIsReady}
                size="lg"
                className="mt-4"
                type="submit"
              >
                Entrar
              </Button>
            </form>
          </Form>

          <GoogleSignInButtonClient isLoading={isLoading} />

          <div className="mt-2 flex flex-col items-center rounded-sm bg-surface-accent-default px-4 py-2">
            <Body className="text-center text-pure" size="xs">
              Você ainda não tem uma conta iClinic?{' '}
            </Body>
            <TextLink size="sm" href="/new/cadastro/email">
              Teste o Afya iClinic gratuitamente
            </TextLink>
          </div>
          <div>
            <Body className="text-center text-pure" size="xxxs">
              Ao clicar em "Entrar", você aceita nossos{' '}
              <Link
                target="_blank"
                className="text-highlight-blue-3"
                href="https://suporte.iclinic.com.br/pt-br/iclinic-termos-de-uso"
              >
                Termos de uso{' '}
              </Link>
              e{' '}
              <Link
                target="_blank"
                className="text-highlight-blue-3"
                href="https://suporte.iclinic.com.br/pt-br/politica-de-privacidade-iclinic"
              >
                Política de Privacidade.
              </Link>
            </Body>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
